import React from 'react';
import { 
  HeroBackground,
  H2,
  BodyLargeText,
  BodyMediumText,
  BodySmallText,
  Row,
  Column
} from 'playful-ui';

import { contact } from './content/Contact';
import { ContactForm } from '../components/ContactForm';
import { ContactContainer } from '../components/ContactContainer';

export class ContactPage extends React.Component {
  static route = '/our-company/contact';
  static title = 'Our company';

  render() {
    return (
      <React.Fragment>
        <HeroBackground textual >
          <H2>{contact.title}</H2>
          <BodyLargeText>{contact.titleText}</BodyLargeText>
        </HeroBackground>
        <ContactContainer className="h-center">
          <ContactForm
            nameLabel={contact.form.nameLabel}
            emailLabel={contact.form.emailLabel}
            messageLabel={contact.form.messageLabel}
            buttonText={contact.form.buttonText}
            formSentMessage={contact.form.formSentMessage}
          />
          <Row className="company-info h-center">
            <Column>
              <BodyMediumText>{contact.info.company.title}:</BodyMediumText>
              {contact.info.company.info.map(info =>
                <BodySmallText>{info}</BodySmallText>)}
            </Column>
            <Column>
              <BodyMediumText>{contact.info.contact.title}:</BodyMediumText>
              {contact.info.contact.info.map(info =>
                <BodySmallText>{info}</BodySmallText>)}
            </Column>
          </Row>
        </ContactContainer>
      </React.Fragment>
    );
  }
}
