import styled from 'styled-components';
import { Row, Column, spacing, Button, media, BodyMediumText, colors } from 'playful-ui';

export const StyledForm = styled(Column.withComponent('form'))`
  & ${Row} {
    margin-top: ${spacing.SMALL};
    flex-wrap: wrap;
    width: 100%;
  };
  & ${Row}:first-child {
    margin-top: 0;
  };
  & div.right-margin {
    margin-right: 40px;
    ${media.tablet`
      margin-right: 0;
    `};
  }
  & div.input {
    flex: 1;
  }
  & ${Button} {
    margin-top: ${spacing.BASE};
  }
  & ${BodyMediumText} {
    margin-top: ${spacing.BASE};
    color: ${colors.tomato};
  }
`;
