import React from 'react';
import { Trans } from '@lingui/react';

import imgCroz from '../assets/our-story/img-croz.svg';
import imgNanobit from '../assets/our-story/nanobit-bw.jpg';
import imgTw from '../assets/our-story/terminal-works-bw.jpg';
import imgInfinum from '../assets/our-story/infinum-bw.jpg';
import imgRimac from '../assets/our-story/rimac-automobili-bw.jpg';
import imgAvalon from '../assets/our-story/avalon-bw.jpg';
import imgEricson from '../assets/our-story/ericson-nikola-tesla-bw.jpg';
import imgElcon from '../assets/our-story/elicon-bw.jpg';
import imgJgl from '../assets/our-story/jgl-bw.jpg';
import imgLogit from '../assets/our-story/logit-bw.jpg';
import imgFive from '../assets/our-story/five-bw.jpg';
import imgCiceron from '../assets/our-story/ciceron-bw.jpg';
import imgKonto from '../assets/our-story/konto-bw.jpg';
import imgAltpro from '../assets/our-story/altpro-bw.jpg';
import imgManjgura from '../assets/our-story/manjgura-bw.jpg';
import imgSurogat from '../assets/our-story/surogat-bw.jpg';
import imgHrCloud from '../assets/our-story/hr-cloud-bw.jpg';
import imgBruketa from '../assets/our-story/bruketa_zinic-bw.jpg';
import imgConcero from '../assets/our-story/concero-bw.jpg';
import imgMicroblink from '../assets/our-story/microblink-bw.jpg';
import imgDgs from '../assets/our-story/dgs-bw.jpg';
import imgTcom from '../assets/our-story/t-com-bw.jpg';

export const ourStory = {
  title: <Trans>Our story</Trans>,
  titleText: (
    <Trans>
      We are here to <b>Mentore, Inspire and Create</b>
    </Trans>
  ),
  mission: <Trans>Our mission</Trans>,
  missionText: (
    <Trans>
      We want to <b>transform</b> people <b>from consumers</b> of technology
      <b>into creators</b> and <b>innovators</b>. One of our long-term goals is to help kids learn
      to
      <b>think creatively, work collaboratively</b> and <b>reason systematically</b> – from the
      youngest age.
    </Trans>
  ),
  card: {
    power: <Trans>Powered by Community</Trans>,
    text: (
      <Trans>
        In 2015. we raised over $36,000 in our Indiegogo crowdfunding campaign, with 280 backers
        from more than 30 countries around the world. We reached our goal and raised $18,000 in only
        3 days!
      </Trans>
    ),
    text2: (
      <React.Fragment>
        <span role="img">✋</span>
        <Trans>
          <b>Thank you</b>
        </Trans>
      </React.Fragment>
    ),
    text3: (
      <Trans>We would not be where we are today without these individuals and organizations</Trans>
    ),
  },
  backersTitle: <Trans>Our backers</Trans>,
  backers: `Tao Yu,
    Liu Zhenghong,
    Stefan Hackethal,
    George Odogu,
    Marijana Hodak Ivanišević,
    Gabriela Makovac,
    Janice Crocker,
    Diners,
    Marin Varnica,
    Peter Feldman,
    TAKUMI MOTOIKE,
    Goran Mileusnic,
    Pedro Henriques,
    Michał Fuksa,
    Ramón López Fernandez,
    Hrvoje.Skof,
    Iva Kolanovic,
    Veljkov86,
    Arriaga Sierra Hermes Adan,
    Matt D'Amico,
    Glück Attila,
    Matija Milković,
    Samir Golub,
    ROBERTO VDOVIC,
    Tomislav Jukic,
    Kenneth Lam,
    Meh.For.Life,
    Vatroslav.Sablic,
    Yves Plumat,
    John,
    Marko Linke,
    Meh.For.Life,
    Davor,
    Bianca Guzman,
    Marija Kudumija Slijepcevic,
    Ikuret,
    David Hochrein,
    Ivan Ormuž,
    YASUHIRO NAKATSUJI,
    DAVORIN CAPAN,
    Ladislav Juric,
    Filip.Milos10,
    Ikalajzic,
    Cklafas,
    Miroslav.Lugaric,
    Ivan Matacic,
    Mario Orak,
    Mario Rajn,
    Xs.Laminar,
    Barbisdaniel,
    Imatic,
    Ivan Hunjadi,
    Yoshitsune Ido,
    Marko Matejčić,
    Glück Attila,
    Sergio A Martinez Garcia,
    E.P. Vermaat,
    Emina.Ahmetovic,
    Hrvoje Hudoletnjak,
    Damir Varevac,
    Josip Sever,
    Charles Lowe,
    KREŠIMIR MACAN,
    John Barbee,
    Kyle Chamberlin,
    Karla Pezelj,
    Patrick Ryan,
    J PAVLICA,
    Luka.Supraha,
    Alen.Bartulovic,
    Sveuciliste U Rijeci; Patrizia Poscic,
    Reslav.Hollos,
    Ana Teskera,,
    Dav,
    Jelena Skorup Juracic,
    TAICHI IWAMOTO,
    Silvija Mrakovcic,
    Vjera.Babic,
    Zrinka Požar,
    Miroslav Vrankic,
    Jennifer Pabst,
    P.Milas,
    RYOHEI SAKAMOTO,
    MISLAV VUKINA,
    Marija Mustac,
    NOBUO MATSUMOTO,
    Andrija Premec,
    Ana Mari,
    Matija Ilijaš,
    Tena Gorjanović,
    Gloria Kraus,
    Tomislav Perko,
    TRPIMIR RENIC,
    Tomislav Preksavec,
    Drago Indjic,
    Cvita-Lucija Bucevic,
    Aron Balog,
    Wade H Robert,
    Lucija Požar,
    Vedrana Miholic,
    Heike,
    Denis Sušac,
    Gordana Ježić - Brkić,
    Fiona,
    C Vodde,
    Neven Tamarut,
    Yves Plumat,
    Goranpg,
    Johan Pretorius,
    Heliobit D.O.O.,
    Nikola Popovic,
    Msrefai,
    Aleta Malinovic,
    Mattie Iverson,
    Ross C Littler,
    Ines Kos,
    Mike Louth,
    Marko Petric,
    Lee Lundrigan,
    David Kim,
    Sirluke789,
    Chan Man Pong,
    Lana Lučin,
    Luykx7,
    Goran Dubcek,
    Fejennings,
    Julie A Garrison,
    Damir Mujic,
    Jasenko Horvat,
    John Kahlbetzer,
    Ante Konjevic,
    Danijel Šaško,
    IVAN FUCEK,
    Danijel.Sasko,
    Hellodean,
    Steve Harwin,
    Mate Rimac,
    Tatjana Pahljina,
    TATJANA SIMIC,
    Matt Kankainen,
    Dustin Maas,
    Tomislav Ljubej,
    Marina,
    Damir Tomić,
    Filip Dobranic,
    Boris Klabučar,
    Pavao Pahljina,
    Ivan Stanic,
    Sagar Kanase
    Alter.Irena,
    Ino Kermc,
    Medo,
    Slobodan Velikic,
    EROL YALI,
    Saša Mrvoš,
    American,
    Elizabeth J Blumenthal,
    Antonio Šutalo,
    Dbraovac,
    Pedro Muslera,
    Charles E McKnight Jr,
    Maria Jesus Garcia Trabazo,
    Albinastimac,
    Matt D'Amico,
    Dbralic9,
    Staffan Hillberg,
    Karl Watson,
    Slobodan Milovanovic,
    Jovica Popović,
    Matija Zulj,
    Hrvoje Marusic,
    David Hochrein,
    David J Hernandez,
    Myles Cameron-Smith,
    Amtest Andrea Ribarić,
    Andro Kuzmanić,
    Filip Stojanac,
    Michael Linke,
    Richard Kohlberger,
    Nikolinapapic,
    Iva Janezic,
    Davor Badrov,
    Helena Vojnovic,
    Kristina Kardum,
    Ivan.Markovic85,
    Snovak,
    Kresimir Antolic,
    Zvone.Lucic,
    Vatroslav.Sablic,
    Krunoslav Šimatović,
    Blazenka Petrak,
    Kskoda,
    Stephen,
    Josh Camarena,
    ROBIN PRETKOVIĆ,
    Radoslav Ostermann,
    Branko117,
    Juraj Peršić,
    Danijela Vlašić,
    DUSAN BASIC,
    Damjan MIklic,
    Malgorzata Mazur,
    John,
    Teo Zikic,
    Zoran Baljak,
    Porin Custic,
    Jholik1,
    Fskobic,
    ALAN PEVEC,
    Ivanlulic,
    Josip Pokrajcic,
    Luka Abrus Fijacko,
    Mary Ippolito,
    Ivan Livić,
    Antonio Šutalo,
    Ivan Vukovic,
    Evan Carter Schwartz,
    Damir Šarčević,
    Visa Electron,
    Nikola Dujmovic,
    Gino Simić,
    Damir Avdic,
    Marin Bezic,
    Branko Borcic,
    Juan Carlos Ivan Perez,
    Izvorka Juric,
    Igor Toplak,
    Dino Bektešević,
    EPB,
    Sasa Skevin,
    Lisandro Cervantes,
    LEOPOLD TIKVIC,
    Krunoslav Kolarec,
    Sandra Vlasic,
    Jerko Skoric,
    Mr A Coman,
    Hrvoje Pupacic,
    Logit Internet Usluge Doo,
    ANTON PREMEC,
    Bend98,
    Samir Golub,
    Maja Buble,
    Zoran Markovic,
    FILIP GOLES,
    Iva Kolanovic,
    Marko Andrijevic,
    Ivan Listeš,
    Alan Sumina,
    Marina Mijatovic,
    Ana Delagic,
    Antun Ivanovic,
    Ema Frcko,
    Mario Novak,
    Jana Blazevic-Marcelja,
    Antonija Zorić,
    Nikola Znaor,
    Edi Budimilic,
    Damir Sabol,
    Daniela Urem,
    Mihovil Barancic,
    Sven Maricic,
    Ivan Brabenec,
    Robert Pasicko,
    Marko Božac,
    Marko Gregovic,
    MATIJA KASNER,
    `,
  companies: {
    title: (
      <Trans>
        These companies contributed to STEMI campaign, so they can donate some of the learning kits
        to Croatian schools
      </Trans>
    ),
    croz: {
      image: imgCroz,
      link: 'https://croz.net',
    },
    companies: [
      {
        image: imgNanobit,
        link: 'http://www.nanobit.co/',
        name: 'Nanobit',
      },
      {
        image: imgTw,
        link: 'https://www.terminalworks.com/',
        name: 'Terminal works',
      },
      {
        image: imgInfinum,
        link: 'https://infinum.co/',
        name: 'Infinum',
      },
      {
        image: imgRimac,
        link: 'http://www.rimac-automobili.com/en/',
        img: 'Rimac automobili',
      },
      {
        image: imgAvalon,
        link: 'https://www.avalon.hr/',
        name: 'Avlon',
      },
      {
        image: imgEricson,
        link: 'https://www.ericsson.hr/',
        name: 'Ericson',
      },
      {
        image: imgElcon,
        link: 'https://www.elcon-cnc.com/',
        name: 'elcon',
      },
      {
        image: imgJgl,
        link: 'http://www.jgl.hr/hr/naslovna/',
        name: 'jgl',
      },
      {
        image: imgLogit,
        link: 'http://www.logit.hr/',
        name: 'Logit',
      },
      {
        image: imgFive,
        link: 'https://five.agency/',
        name: 'five',
      },
      {
        image: imgCiceron,
        link: 'https://ciceron.hr/',
        name: 'ciceron',
      },
      {
        image: imgKonto,
        link: 'https://www.konto-ri.hr/',
        name: 'Konto',
      },
      {
        image: imgAltpro,
        link: 'https://altpro.hr/en',
        name: 'altpro',
      },
      {
        image: imgManjgura,
        link: 'https://manjgura.hr/en/',
        name: 'manjgura',
      },
      {
        image: imgSurogat,
        link: 'http://surogat.com.hr/',
        name: 'surogat',
      },
      {
        image: imgHrCloud,
        link: 'https://www.hrcloud.com/',
        name: 'hr cloud',
      },
      {
        image: imgBruketa,
        link: 'http://bruketa-zinic.com/hr/',
        name: 'bruketa i zinic',
      },
      {
        image: imgConcero,
        link: 'http://www.concero.hr/',
        name: 'concero',
      },
      {
        image: imgMicroblink,
        link: 'https://microblink.com/',
        name: 'mikroblink',
      },
      {
        image: imgDgs,
        link: 'https://www.dgs.hr/home',
        name: 'dgs',
      },
      {
        image: imgTcom,
        link: 'https://www.t.ht.hr/',
        name: 'tcom',
      },
    ],
  },
  creator: <Trans>Everyone can become a creator and innovator</Trans>,
  creatorButton: <Trans>SEE HOW</Trans>,
};
