import React from 'react';
import { Trans } from '@lingui/react';

import { A } from 'playful-ui';

export const contact = {
  title: <Trans>Contact us</Trans>,
  titleText:
    <React.Fragment>
      <Trans>Send us your thoughts or questions to</Trans>
      <A href="mailto:info@stemi.education"> info@stemi.education </A>
      <Trans>or use the form below:</Trans>
    </React.Fragment>,
  form: {
    nameLabel: <Trans>YOUR NAME</Trans>,
    emailLabel: <Trans>EMAIL</Trans>,
    messageLabel: <Trans>MESSAGE</Trans>,
    buttonText: <Trans>Send message</Trans>,
    formSentMessage: <Trans>Thank you, we'll get back to you soon.</Trans>
  },
  info: {
    company: {
      title: <Trans>Company info</Trans>,
      info: [
        'STEMI d.o.o.',
        'Radmile Matejčič 10',
        '51 000, Rijeka, Croatia',
        'OIB: 91713184849',
        'VATID: HR91713184849',
      ]
    },
    contact: {
      title: <Trans>Contact info</Trans>,
      info: [
        'STEMI d.o.o.',
        'Lastovska ulica 23',
        '5th floor',
        '10000 Zagreb, Croatia',
        'info@stemi.education',
        'tel: +385914440605',
      ]
    }
  },
}
