import React from 'react';
import { Trans } from '@lingui/react';

import ImgMarin from '../assets/team-images/ic-marin.png'
import ImgAntun from '../assets/team-images/ic-antun.png'
import ImgJosip from '../assets/team-images/ic-josip.png'
import ImgLuka from '../assets/team-images/ic-luka.png'
import ImgVlatko from '../assets/team-images/ic-vlatko.png'
import ImgMia from '../assets/team-images/ic-mia.png'
import ImgDado from '../assets/team-images/ic-dado.png'

export const ourTeam = {
  title: <Trans>Meet Our Team</Trans>,
  titleText: <Trans>One of our long-term goals is to help kids learn to think creatively, work collaboratively and reason systematically – from the youngest age.</Trans>,
  team: [
    {
      image: ImgMia,
      name: 'Mia Antolić',
      title: <Trans>Head of Education</Trans>,
      email: 'mia@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/mantolic/'
    },
    {
      image: ImgMarin,
      name: 'Marin Trošelj',
      title: <Trans>Co-founder & CEO</Trans>,
      email: 'marin@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/mtroselj/'
    },
    {
      image: ImgLuka,
      name: 'Luka Fućek',
      title: <Trans>Co-founder & CTO</Trans>,
      email: 'luka@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/lukafucek/'
    },
    {
      image: ImgAntun,
      name: 'Antun Vukičević',
      title: <Trans>Co-founder & COO</Trans>,
      email: 'antun@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/antun-vukicevic/'
    },
    {
      image: ImgVlatko,
      name: 'Vlatko Klabučar',
      title: <Trans>Co-founder & Software Lead</Trans>,
      email: 'vlatko@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/klabucar/'
    },
    {
      image: ImgJosip,
      name: 'Josip Vukičević',
      title: <Trans>Co-founder & Creative Director</Trans>,
      email: 'josip@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/jvukicevic/'
    },
    {
      image: ImgDado,
      name: 'Slobodan Velikić',
      title: <Trans>Sales Director</Trans>,
      email: 'slobodan@stemi.education',
      linkedIn: 'https://www.linkedin.com/in/slobodan-velikic-abb684b5/'
    },
  ]
}
