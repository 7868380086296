import React from 'react';
import { TextInput, TextArea, Row, Button, BodyMediumText } from 'playful-ui';

import { StyledForm } from './StyledForm';

export class DistributorForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formSent: false,
      name: '',
      company: '',
      email: '',
    };
  }

  handleSentForm = () => {
    if (this.state.name && this.state.company && this.state.email) {
      this.setState({ formSent: true });
    }
  };

  handleChangeName = e => this.setState({ name: e.target.value });
  handleChangeCompany = e => this.setState({ company: e.target.value });
  handleChangeEmail = e => this.setState({ email: e.target.value });

  render() {
    const { formSent } = this.state;
    const {
      nameLabel,
      companyLabel,
      emailLabel,
      countryLabel,
      messageLabel,
      buttonText,
      formSentMessage,
    } = this.props;
    return (
      <StyledForm
        className="h-center"
        method="POST"
        action="https://formspree.io/sales@stemi.education"
        autoComplete="off"
        onSubmit={this.handleSentForm}
      >
        <Row className="h-center">
          <TextInput
            name="name"
            className="input right-margin"
            onChange={this.handleChangeName}
            required
          >
            <label>{nameLabel}</label>
          </TextInput>
          <TextInput
            name="company"
            className="input"
            onChange={this.handleChangeCompany}
            required
          >
            <label>{companyLabel}</label>
          </TextInput>
        </Row>
        <Row className="h-center">
          <TextInput
            type="email"
            name="email"
            className="input right-margin"
            onChange={this.handleChangeEmail}
            required
          >
            <label>{emailLabel}</label>
          </TextInput>
          <TextInput name="country" className="input">
            <label>{countryLabel}</label>
          </TextInput>
        </Row>
        <Row className="h-center">
          <TextArea name="message" className="input" rows="6">
            <label>{messageLabel}</label>
          </TextArea>
        </Row>
        <Button type="submit">{buttonText}</Button>
        {formSent && <BodyMediumText>{formSentMessage}</BodyMediumText>}
      </StyledForm>
    );
  }
}
