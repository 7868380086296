import styled from 'styled-components';
import { Column, media, spacing } from 'playful-ui';

export const ContactContainer = styled(Column)`
  margin-bottom: 120px;
  ${media.phone`
    margin-bottom: 80px;
  `};

  & form {
    margin-top: 80px;
    max-width: 950px;
    ${media.tablet`
      max-width: 656px;
    `};
    ${media.phone`
      margin-top: ${spacing.BASE};
      max-width: 335px;
    `};
  };

  & div.company-info {
    margin-top: ${spacing.LARGE};
    max-width: 754px;
    ${media.phone`
      max-width: 335px;
    `};
  };
  & div.company-info p {
    width: unset;
  };
  & div.company-info > div:last-child {
    margin-left: ${spacing.LARGE};
    ${media.phone`
      margin-left: ${spacing.SMALL};
    `};
  };
  & div.company-info > div > p:first-child {
    margin-bottom: ${spacing.TINY};
  };
`;
