import React from 'react';
import { TextInput, TextArea, Row, Button, BodyMediumText, } from 'playful-ui';

import { StyledForm } from './StyledForm';

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formSent: false,
      name: '',
      email: '',
    };
  }

  handleSentForm = () => {
    if (this.state.name && this.state.email) {
      this.setState({ formSent: true });
    }
  }
  
  handleChangeName = (e) => this.setState({ name: e.target.value });
  handleChangeEmail = (e) => this.setState({ email: e.target.value });

  render() {
    const { formSent } = this.state;
    const { nameLabel, emailLabel, messageLabel, buttonText, formSentMessage } = this.props;
    return (
      <StyledForm
        className="h-center"
        method="POST"
        action="https://formspree.io/info@stemi.education"
        autoComplete="off"
        onSubmit={this.handleSentForm}
      >
        <Row className="h-center">
          <TextInput name="name" className="input right-margin" onChange={this.handleChangeName} required>
            <label>{nameLabel}</label>
          </TextInput>
          <TextInput type="email" name="email" className="input" onChange={this.handleChangeEmail} required>
            <label>{emailLabel}</label>
          </TextInput>
        </Row>
        <Row className="h-center">
          <TextArea name="message" className="input" rows="6">
            <label>{messageLabel}</label>
          </TextArea>
        </Row>
        <Button type="submit">{buttonText}</Button>
        {formSent &&
          <BodyMediumText>
            {formSentMessage}
          </BodyMediumText>
        }
      </StyledForm>
    );
  }
}
