import styled from 'styled-components';
import { colors, media, spacing } from 'playful-ui';

import { MemberCard } from './MemberCard';

export const OurTeamContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.white};

  margin-bottom: 120px;
  ${media.tablet`
    margin-bottom: 80px;
  `};

  & div.members {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 950px;
    ${media.tablet`
      max-width: 576px;
    `};
    ${media.phone`
      max-width: 320px;
      justify-content: center;
    `};
  }

  & ${MemberCard} {
    margin-top: 80px;
    ${media.phone`
      margin-top: ${spacing.LARGE};
    `};
  }
  
`;
