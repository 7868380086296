import styled from 'styled-components';
import { media, spacing, H4, colors, BodySmallText, Row } from 'playful-ui';

export const MemberCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  max-width: 300px;
  min-width: 300px;

  ${media.tablet`
    max-width: 236px;
    min-width: 236px;
  `};

  & img {
    margin-bottom: ${spacing.TINY};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    max-width: 157px;
    max-height: 157px; 
  };

  & ${H4} {
    margin-bottom: 4px;
  };

  & ${BodySmallText} {
    color: ${colors.tomato};
    margin-bottom: ${spacing.SMALL};
  };

  & ${Row} a {
    display: inline-flex;
    margin-left: ${spacing.SMALL};
  };
  & ${Row} a:first-child {
    margin-left: 0;
  };
  & ${Row} a svg {
    opacity: 0.3;
    transition: opacity 0.2s ease-out;
  };
  & ${Row} a svg g path:last-child {
    fill: ${colors.midnight};
  };
  & ${Row} a:hover svg {
    opacity: 1;
  };
`; 
