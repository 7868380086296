import React from 'react';
import { Trans } from '@lingui/react';
import { A } from 'playful-ui';

import ImgSvijetMedija from '../assets/distributors/img-svijet-medija.png';
import ImgBazzar from '../assets/distributors/img-bazzar.png';
import Links from '../assets/distributors/img-links.png';
import eKupi from '../assets/distributors/img-ekupi.png';
import A1 from '../assets/distributors/img-a1.png';
import Oomipood from '../assets/distributors/img-oomipood.png';
import RobotShop from '../assets/distributors/img-robot-shop.png';
import Mikrotron from '../assets/distributors/img-mikrotron.png';
import Botland from '../assets/distributors/img-botland.png';
import MilestoneC from '../assets/distributors/img-milestone-c.png';
import GoTronic from '../assets/distributors/img-gotronic.jpg';
import AcGears from '../assets/distributors/img-acGears.png';
import Sync from '../assets/distributors/img-sync.png';
import Amazon from '../assets/distributors/img-amazon.png';
import Conrad from '../assets/distributors/img-conrad.png';

export const pageTitle = "Official STEMI distributors";

export const distributors = [
  {
    image: Amazon,
    link: 'https://www.amazon.com/STEMI-Hexapod-Robot-Educational-Application/dp/B07KB4G4R6',
    name: 'Amazon',
    country: <Trans>World</Trans>
  },
  {
    image: Conrad,
    link: 'https://www.conrad.com/p/stemi-robot-assembly-kit-12345-2136227',
    name: 'Conrad',
    country: <Trans>World</Trans>
  },
  {
    image: Sync,
    link: 'https://sync.ba/shop/robotika/stemi-hexapod-robot/',
    name: 'Sync',
    country: <Trans>World</Trans>
  },
  {
    image: RobotShop,
    link: 'https://www.robotshop.com/en/stemi.html',
    name: 'RobotShop',
    country: <Trans>World</Trans>
  },
  {
    image: MilestoneC,
    link: 'https://www.milestonec.com/',
    name: 'Milestone C',
    country: <Trans>World</Trans>
  },
  {
    image: AcGears,
    link: 'https://www.acgears.com/',
    name: 'AcGears',
    country: <Trans>World</Trans>
  },
  {
    image: Oomipood,
    link: 'https://www.oomipood.ee/en/product/stemi_hexapod_robot',
    name: 'Oommipood',
    country: <Trans>Europe</Trans>
  },
  {
    image: Botland,
    link: 'https://botland.com.pl/pl/roboty-kroczace/13247-stemi-hexapod-szescionozny-robot-kroczacy-zestaw-do-samodzielnego-zlozenia.html?search_query=stemi&results=1',
    name: 'Botland',
    country: <Trans>Europe</Trans>
  },
  {
    image: GoTronic,
    link: 'https://www.gotronic.fr/',
    name: 'Go Tronic',
    country: <Trans>Europe</Trans>
  },
  {
    image: A1,
    link: 'http://www.vipnet.hr/stemi-hexapod-robot',
    name: 'A1',
    country: <Trans>Europe</Trans>
  },
  {
    image: ImgSvijetMedija,
    link: 'https://www.svijet-medija.hr/art/edukativni-robot-stemi-hexapod-2018/88568',
    name: 'Svijet Medija',
    country: <Trans>Europe</Trans>
  },
  {
    image: ImgBazzar,
    link: 'https://bazzar.hr/stemi/stemi-hexapod-robot-za-ucenje-979758',
    name: 'Bazzar',
    country: <Trans>Europe</Trans>
  },
  {
    image: Links,
    link: 'https://www.links.hr/hr/robot-stemi-hexapod-2018-stem-edukacijski-set-za-djecu-410503008',
    name: 'Links',
    country: <Trans>Europe</Trans>
  },
  {
    image: eKupi,
    link: 'https://www.ekupi.hr/STEMI-Hexapod---Komplet-za-ucenje-robotike-i-inzenjerstva-1325972.aspx',
    name: 'eKupi',
    country: <Trans>Europe</Trans>
  },
  {
    image: Mikrotron,
    link: 'https://www.diykits.eu/shophr/products/p_11368',
    name: 'Mikrotron',
    country: <Trans>Europe</Trans>
  },
]

const contactMail = "sales@stemi.education"

export const newDistributor = {
  sectionTitle: <Trans>Join our distributors network</Trans>,
  contactUs: <Trans>Contact us via <A href={`mailto:${contactMail}`}>{contactMail}</A> or fill the <b>form bellow</b></Trans>,
  form: {
    nameLabel: <Trans>YOUR NAME</Trans>,
    companyLabel: <Trans>COMPANY NAME</Trans>,
    emailLabel: <Trans>EMAIL</Trans>,
    countryLabel: <Trans>COUNTRY</Trans>,
    messageLabel: <Trans>MESSAGE</Trans>,
    buttonText: <Trans>Send message</Trans>,
    formSentMessage: <Trans>Thank you, we'll get back to you soon.</Trans>
  }
}
