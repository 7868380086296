import React from 'react';
import { Trans } from '@lingui/react';

export const text = {
  getHexapod: <Trans>Get Hexapod</Trans>,
  roboticsForSchools: <Trans>Robotics for Schools</Trans>,
  hardware: <Trans>Hardware</Trans>,
  getInTouch: <Trans>Get in touch</Trans>,
  products: <Trans>Products</Trans>,
  stemiLab: <Trans>Stemi Lab</Trans>,
  signUp: <Trans>Sign Up</Trans>,
  signIn: <Trans>Sign In</Trans>,
  signOut: <Trans>Sign Out</Trans>,
};
