import styled from 'styled-components';
import {
  Column,
  BodyLargeText,
  spacing,
  media,
  Section,
  H2,
  BodyXSmallText,
  colors,
} from 'playful-ui';

import { MemberCard } from './MemberCard';

export const OurStoryContainer = styled(Column)`
  margin-bottom: 120px;
  ${media.phone`
    margin-bottom: 80px;
  `};

  & div.hero-background > div:last-child {
    flex-direction: column;
    padding-bottom: 0;
  };
  & div.hero-background > div:last-child > ${BodyLargeText} {
    margin-top: ${spacing.SMALL};
  };
  & div.hero-background > div:last-child > img {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    margin-top: ${spacing.LARGE};
    max-height: 523px;
    @media (min-width: 1200px) {
      max-width: 100%;
    };
    @media (min-width: 900px) and (max-width: 1200px) {
      max-width: 85%;
    };
    ${media.phone`
      margin-top: ${spacing.BASE}
      max-height: 188px;
    `};
  };
  
  & ${Section} > ${H2}, & ${Section} > p {
    text-align: center;
  };
  & ${Section} > ${BodyXSmallText} {
    margin-top: ${spacing.BASE};
    ${media.phone`
      margin-top: ${spacing.SMALL};
    `};
  }
  & ${Section}.with-button {
    display:flex;
    flex-direction: column;
    align-items: center;
  };
  & ${Section}.with-button > a {
    margin-top: ${spacing.BASE_PLUS};
    ${media.phone`
      margin-top: ${spacing.BASE};
    `};
  };

  & div.companies {
    margin-top: 120px;
    ${media.phone`
      margin-top: 80px;
    `}
    width: 100%;
    background-color: ${colors.applyOpacity(colors.whitesmoke, 0.4)};
  };
  & div.companies p {
    margin-top: ${spacing.LARGE};
    text-align: center;
    max-width: 754px;
    ${media.tablet`
      width: 624px; 
    `};
    ${media.phone`
      margin-top: ${spacing.SMALL};
      width: 335px; 
    `};
  };

  & img.croz {
    height: 120px;
    margin: ${spacing.LARGE} 0;
    ${media.phone`
      margin-top: ${spacing.BASE} 0;
    `};
  }

  & div.companies-logo {
    margin-bottom: ${spacing.LARGE};
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 950px;
    ${media.tablet`
      max-width: 576px;
      justify-content: space-between;
    `};
    ${media.phone`
      max-width: 320px;
      justify-content: center;
      margin-bottom: ${spacing.BASE};
    `};
  };

  & ${MemberCard} {
    margin-top: ${spacing.LARGE};
    ${media.phone`
      margin-top: ${spacing.BASE};
    `};
  }
`;
