import React from 'react';
import {
  Section,
  SectionHeader,
  H2,
  H4,
  BodySmallText,
  BodyLargeText,
  Column,
} from 'playful-ui';

import {
  pageTitle,
  distributors,
  newDistributor,
} from './content/Distributors';
import { DistributorsContainer } from '../components/DistributorsContainer';
import { MemberCard } from '../components/MemberCard';
import { DistributorForm } from '../components/DistributorForm';

export class DistributorsPage extends React.Component {
  static route = '/our-company/distributors';
  static title = pageTitle;

  render() {
    return (
      <DistributorsContainer className="v-center h-center">
        <div className="distributors">
          {distributors.map((distributor, i) => (
            <MemberCard key={`distributors${i}`}>
              <a
                href={distributor.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Column className="h-center">
                  <img alt={distributor.name} src={distributor.image} />
                  <H4>{distributor.name}</H4>
                </Column>
              </a>
              <BodySmallText>{distributor.country}</BodySmallText>
            </MemberCard>
          ))}
        </div>
        <Section className="large textual">
          <SectionHeader index={2} />
          <H2>{newDistributor.sectionTitle}</H2>
          <BodyLargeText>{newDistributor.contactUs}</BodyLargeText>
          <DistributorForm
            nameLabel={newDistributor.form.nameLabel}
            companyLabel={newDistributor.form.companyLabel}
            emailLabel={newDistributor.form.emailLabel}
            countryLabel={newDistributor.form.countryLabel}
            messageLabel={newDistributor.form.messageLabel}
            buttonText={newDistributor.form.buttonText}
            formSentMessage={newDistributor.form.formSentMessage}
          />
        </Section>
      </DistributorsContainer>
    );
  }
}
