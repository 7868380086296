import React from 'react';
import {
  HeroBackground,
  H2,
  BodyLargeText,
  BodyXSmallText,
  Section,
  SectionHeader,
  Card,
  Caption,
  Column,
} from 'playful-ui';
import { AppLinkButton } from 'components';
import { HexapodPage } from 'modules/products';

import imgHero from './assets/our-story/img-hero-our-story.jpg';
import imgIndiegogo from './assets/our-story/img-founded-with-indiegogo.png';
import { OurStoryContainer } from '../components/OurStoryContainer';
import { ourStory } from './content/OurStory';
import { MemberCard } from '../components/MemberCard';


export class OurStoryPage extends React.Component {
  static route = "/our-company/our-story";

  render() {
    return (
      <OurStoryContainer>
        <HeroBackground className="hero-background">
          <H2>{ourStory.title}</H2>
          <BodyLargeText>{ourStory.titleText}</BodyLargeText>
          <img src={imgHero} alt="teaching-kids" />
        </HeroBackground>
        <Section className="large textual">
          <SectionHeader index={2} />
          <H2>{ourStory.mission}</H2>
          <BodyLargeText>{ourStory.missionText}</BodyLargeText>
        </Section>
        <Section className="large">
          <Card className="silver" images={[imgIndiegogo]}>
            <Caption>STEMI HEXAPOD</Caption>
            <H2>{ourStory.card.power}</H2>
            <BodyLargeText>{ourStory.card.text}</BodyLargeText>
            <BodyLargeText>{ourStory.card.text2}</BodyLargeText>
            <BodyLargeText>{ourStory.card.text3}</BodyLargeText>
          </Card>
        </Section>
        <Section className="large textual">
          <SectionHeader index={3} />
          <H2>{ourStory.backersTitle}</H2>
          <BodyXSmallText>{ourStory.backers}</BodyXSmallText>
        </Section>
        <Column className="companies h-center">
          <BodyLargeText>{ourStory.companies.title}</BodyLargeText>
          <a target="_blank" href={ourStory.companies.croz.link} rel="noopener noreferrer">
            <img className="croz" src={ourStory.companies.croz.image} alt="croz" />
          </a>
          <div className="companies-logo">
            {
              ourStory.companies.companies.map((distributor, i) =>
                <MemberCard key={`companies${i}`}>
                  <a href={distributor.link} target="_blank" rel="noopener noreferrer">
                    <Column className="h-center">
                      <img alt={distributor.name} src={distributor.image} />
                    </Column>
                  </a>
                </MemberCard>
              )
            }
          </div>
        </Column>
        <Section className="large textual with-button">
          <SectionHeader index={1} />
          <H2>{ourStory.creator}</H2>
          <AppLinkButton className="large" to={HexapodPage.route}>
            {ourStory.creatorButton}
          </AppLinkButton>
        </Section>
      </OurStoryContainer>
    );
  }

}
