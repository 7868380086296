import React from 'react';
import { HeroBackground, H2, H4, BodyLargeText, BodySmallText, Row, Icon } from 'playful-ui';

import { ourTeam } from './content/OurTeam';
import { OurTeamContainer } from '../components/OurTeamContainer';
import { MemberCard } from '../components/MemberCard';

export class OurTeamPage extends React.Component {
  static route = "/our-company/our-team";

  render() {
    return (
      <React.Fragment>
        <HeroBackground textual>
          <H2>{ourTeam.title}</H2>
          <BodyLargeText>{ourTeam.titleText}</BodyLargeText>
        </HeroBackground>
        <OurTeamContainer>
          <div className="members">
            {
              ourTeam.team.map((member, i) =>
                <MemberCard>
                  <img alt={member.name} src={member.image} />
                  <H4>{member.name}</H4>
                  <BodySmallText>{member.title}</BodySmallText>
                  <Row>
                    <a href={`mailto:${member.email}`}>
                      <Icon name="Email" />
                    </a>
                    <a href={member.linkedIn} target="_blank" rel="noopener noreferrer">
                      <Icon name="LinkedIn" />
                    </a>
                  </Row>
                </MemberCard>
              )
            }
          </div>
        </OurTeamContainer>
      </React.Fragment>
    )
  }
}
