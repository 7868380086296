import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'gatsby';
import styled from 'styled-components';
import classnames from 'classnames';

import {
  ButtonWithoutStyle,
  Button,
  Icon,
  Navigation,
  Modal,
  colors,
  media,
  withScroll,
} from 'playful-ui';
import { signOut } from 'stemi-firebase';

import { IS_BROWSER } from 'consts';
import { ProductsPage } from 'modules/products';
import { StemiLabPage } from 'modules/lab';
import { ContactPage } from 'modules/our-company';
import { getCurrentUser } from 'modules/auth';
import * as cart from 'modules/cart';

import { ReactComponent as Logo } from '../assets/img-stemi-logo.svg';
import { text } from '../content/text';
import MainSidebar from './MainSidebar';

const NavigationMobileScroll = styled(Navigation)`
  ${media.phone`
    &.mobileSticky {
      position: sticky;
      transform: translateY(-56px);
    }
    &.mobileSticky.show {
      transition: transform 0.4s ease-in 0.2s;
      transform: translateY(0px);
      padding-top: 0;
      background-color: ${colors.white};
      box-shadow: 0 1px ${colors.whitesmoke}, 0 -1px ${colors.whitesmoke};
    }
  `}
`;

class MainNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      oldScrollY: 0,
      currentScrollY: 0,
      scrollSpeed: 0,
      isMobileSticky: false,
      showStickyNav: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const { addScrollListener } = this.props;
    // addScrollListener(this.handleScroll);
    // Guard against Gatsby SSR
    if (IS_BROWSER) {
      const {
        disableBodyScroll,
        enableBodyScroll,
      } = require('body-scroll-lock');
      this.disableBodyScroll = disableBodyScroll;
      this.enableBodyScroll = enableBodyScroll;
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-vars
    const { removeScrollListener } = this.props;
    //removeScrollListener(this.handleScroll);
    this.disableBodyScroll = null;
    this.enableBodyScroll = null;
  }

  handleScroll = (event, scroll) => {
    if (media.getScreenSize().x <= media.sizes.phone) {
      const { scrollY, scrollSpeedY } = scroll;
      if (scrollY > 56) {
        this.setState({ isMobileSticky: true });
      }
      if (scrollSpeedY > 0) {
        this.setState({ showStickyNav: false });
      }
      if (scrollSpeedY < 0) {
        this.setState({ showStickyNav: true });
      }
    }
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
    this.disableBodyScroll && this.disableBodyScroll(document.body);
  };

  handleCloseModal = e => {
    // close modal only when clicked on a element without 'dont-close-modal' className
    const targetClassNames = e.target.getAttribute('class');
    if (
      !targetClassNames ||
      (targetClassNames && !targetClassNames.includes('dont-close-modal'))
    ) {
      this.setState({ showModal: false });
      this.enableBodyScroll && this.enableBodyScroll(document.body);
    }
  };

  render() {
    const { isCartEmpty, user } = this.props;
    const showSignInSignUp = user.isEmpty() || user.isAnonymous;
    return (
      <React.Fragment>
        <NavigationMobileScroll
          className={classnames({
            mobileSticky: this.state.isMobileSticky,
            show: this.state.showStickyNav,
          })}
        >
          <Navigation.Item variant="left" className="logo">
            <a
              className="nav-link"
              href={'https://stemi.education'}
              target="_blank"
            >
              <Logo />
            </a>
          </Navigation.Item>

          <Navigation.Item variant="left" className="hide-phone">
            <a
              className="nav-link"
              href={'https://robotics.milestonec.com'}
              target="_blank"
            >
              {text.roboticsForSchools}
            </a>
          </Navigation.Item>

          <Navigation.Item variant="left" className="hide-phone">
            <Link className="nav-link" to={StemiLabPage.route}>
              {text.stemiLab}
            </Link>
          </Navigation.Item>

          <Navigation.Item
            variant="right"
            className="hide-tablet hide-tablet-landscape hide-desktop"
            style={{ marginRight: '0px' }}
          >
            <ButtonWithoutStyle onClick={this.handleOpenModal}>
              <Icon name="Menu" />
            </ButtonWithoutStyle>
          </Navigation.Item>
        </NavigationMobileScroll>

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
        >
          <MainSidebar handleCloseModal={this.handleCloseModal} />
        </Modal>
      </React.Fragment>
    );
  }
}

MainNavigation.propTypes = {
  isCartEmpty: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    isCartEmpty: cart.isCartEmpty(state),
    user: getCurrentUser(state),
  };
};

export default compose(
  withScroll,
  connect(
    mapStateToProps,
    { signOut },
  ),
)(MainNavigation);
