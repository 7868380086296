import styled from 'styled-components';
import { Column, spacing, media, Section, H2, BodyLargeText } from 'playful-ui';

import { MemberCard } from './MemberCard';

export const DistributorsContainer = styled(Column)`
  margin-bottom: 120px;
  ${media.phone`
    margin-top: ${spacing.TINY};
    margin-bottom: 80px;
  `};
  & ${Section} > ${H2}, & ${Section} > ${BodyLargeText} {
    text-align: center;
  };
  & ${Section} > ${BodyLargeText} {
    margin-bottom: ${spacing.BASE};
    ${media.phone`
      margin-bottom: ${spacing.TINY};
    `};
  };

  & div.distributors {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 950px;
    ${media.tablet`
      max-width: 576px;
      justify-content: space-between;
    `};
    ${media.phone`
      max-width: 320px;
      justify-content: center;
    `};
  }

  & ${MemberCard} {
    margin-top: 80px;
    ${media.phone`
      margin-top: ${spacing.LARGE};
    `};
  }
`;
