import styled, { css } from 'styled-components';

import {
  Row,
  Column,
  colors,
  fonts,
  spacing,
  H4,
  Icon,
  FooterSectionText,
  FooterCategoryText,
  HorizontalRule
} from 'playful-ui';

export const NavigationSidebarContainer = styled(Column)`
  background-color: ${colors.darkblue};
  width: ${props => (props.user ? '230px' : '302px')};
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;

  & svg g path:nth-child(2) {
    fill: ${colors.white};
  }
  & ${Row}.icons {
    height: 72px;
    width: 100%;
  }
  & ${HorizontalRule} {
    margin: 0;
    opacity: 0.5;
    border-top: 0px;
    border-color: ${colors.persianblue};
  }
  & ${HorizontalRule}.navigation-links-separator {
    margin: ${spacing.BASE} 0;
  }
  & a.navigation-link {
    width: fit-content;
    margin-left: ${spacing.BASE};
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.SMALL};
  }
  & a.account-navigation-link {
    opacity: 0.9;
    width: fit-content;
    margin-left: ${spacing.BASE};
    margin-top: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    ${css`
      ${fonts.navigationLink};
      color: ${colors.white};
    `};
  }
  & a.account-navigation-link:last-child {
    margin-bottom: ${spacing.SMALL};
    opacity: 0.5;
  }
  & a.account-navigation-link:first-child {
    margin-top: ${spacing.SMALL};
  }
  & ${Row}.account-information {
    height: ${spacing.XLARGE_PLUS};
  }
  & ${Row}.account-information ${Icon} {
    margin-left: ${spacing.BASE};
    margin-right: ${spacing.SMALL};
  }
  & ${Row}.account-information ${FooterCategoryText} {
    opacity: 0.5;
    margin-top: 1px;
  }
  & div.account-navigation {
    flex: unset;
    background-color: ${colors.persianblue};
  }
  & ${H4}, ${FooterCategoryText}, ${FooterSectionText} {
    color: ${colors.white};
  }
  & a {
    display: inline-flex;
    text-decoration: none;
  }
`;
