import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import {
  Row,
  Icon,
  ButtonWithoutStyle,
  colors,
  H4,
  Column,
  HorizontalRule,
} from 'playful-ui';
import { signOut } from 'stemi-firebase';

import { getCurrentUser } from 'modules/auth';
import { ProductsPage } from 'modules/products';
import { StemiLabPage } from 'modules/lab';
import { CartPage } from 'modules/cart';
import { ContactPage } from 'modules/our-company';

import { text } from '../content/text';
import { NavigationSidebarContainer } from './NavigationSidebarContainer';

const MainSidebar = props => {
  const { handleCloseModal, cart, user, signOut } = props;
  const signedOut = user.isEmpty() || user.isAnonymous;
  return (
    <NavigationSidebarContainer
      className="dont-close-modal"
      style={{ width: '230px' }}
      onClick={handleCloseModal}
    >
      <Row className="space-between v-center icons dont-close-modal">
        <a
          className="navigation-link"
          href={'https://stemi.education'}
          target="_blank"
          style={{ marginLeft: '20px' }}
        >
          <Icon name="Home" />
        </a>
        <ButtonWithoutStyle style={{ marginRight: '20px' }}>
          <Icon name="Close" />
        </ButtonWithoutStyle>
      </Row>
      <HorizontalRule className="dont-close-modal" />
      <Column className="dont-close-modal">
        <Column className="v-end dont-close-modal" style={{ flex: '3' }}>
          <a
            className="navigation-link"
            href={'https://robotics.milestonec.com'}
            target="_blank"
          >
            <H4>{text.roboticsForSchools}</H4>
          </a>

          <Link className="navigation-link" to={StemiLabPage.route}>
            <H4>{text.stemiLab}</H4>
          </Link>
        </Column>
      </Column>
    </NavigationSidebarContainer>
  );
};

const mapStateToProps = state => {
  return {
    cart: state => state.cart,
    user: getCurrentUser(state),
  };
};

export default connect(
  mapStateToProps,
  { signOut },
)(MainSidebar);
